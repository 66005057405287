import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

const AppLoading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography style={{ fontFamily: "'Zeyada', cursive", fontSize: 72 }}>
          Journal
        </Typography>
        <CircularProgress size={140} thickness={2} />
      </Box>
    </Box>
  );
};

export default AppLoading;
